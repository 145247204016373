<template>
  <div class="dashboard">
    <div class="row">
      <div class="flex xs12">
        <va-card :title="$t('dashboard.info.title')">
          <div class="mb-3" v-for="(msg,msgK) in newsList" :key="msgK">
            <va-notification :color="msg.newsType">
              {{ msg.showStartS }}: &nbsp;
              {{ msg.newsText }}
            </va-notification>
          </div>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'dashboard',
  data () {
    this.getNews()
    return {
      newsList: {},
    }
  },
  methods: {
    getNews () {
      this.$srvconn.sendMessage('getCurrentNews')
        .then(response => {
          if (response.msgType === 'getCurrentNews') {
            this.newsList = response.userData.newsList
          }
          // console.log(response)
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
